import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/joy';
import {
  GigPreviewItemProps,
  GigStatus,
  GigPreviewItemContext,
  GigApplicationStatus,
  ToastMode,
} from '../../types/interfaces';
import { DateTime } from 'luxon';
import { BookmarkFilled, BriefcaseIcon, MapPinIcon } from '../../assets/svg';
import { BookmarkOutline, CheckCircle } from '../../assets/svg/index';
import HorizontalSpacer from '../HorizontalSpacer';
import { useCallback, useState } from 'react';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import HourlyRateLabel from '../HourlyRateLabel/index';
import VerticalSpacer from '../VerticalSpacer';
import useMobileMode from '../../hooks/useMobileMode';
import { addBookmark, deleteBookmark } from '../../api/user';
import triggerToast from '../../utils/triggerToast';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';
import QuickHireLabel from '../QuickHireLabel';

const GigPreviewItem = ({
  id,
  imageUrl,
  name,
  ISO8601DateTimeStart,
  ISO8601DateTimeEnd,
  location,
  hourlyRate,
  estimatedPrice,
  clientRating,
  bookmarked,
  bonus,
  city,
  zipCode,
  isFulltime,
  status,
  componentContext,
  applicationStatus,
  isAnonymous,
  isExternal = false,
  handleClick,
}: GigPreviewItemProps) => {
  const formattedDateTimeStart = DateTime.fromISO(ISO8601DateTimeStart).toFormat('LLLL dd, h:mm a');
  const formattedDateTimeEnd = DateTime.fromISO(ISO8601DateTimeEnd).toFormat('h:mm a');

  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);
  const [isGigBookmarked, setIsGigBookmarked] = useState(bookmarked);

  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '0 0 0 0' : '16px 48px 0 48px';

  const [isLoadingImage, setIsLoadingImage] = useState(true);

  const containerStyle =
    componentContext === GigPreviewItemContext.GigDetails
      ? {
          borderRadius: mobileAwareBorderRadius,
          margin: mobileAwareHorizontalMargin,
          paddingRight: '16px',
          paddingLeft: '16px',
          backgroundColor: Colors.White,
        }
      : {};

  const handleBookmarkClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      if (isBookmarkLoading) return;

      setIsBookmarkLoading(true);
      try {
        if (isGigBookmarked) {
          await deleteBookmark({ gigId: id });
          setIsGigBookmarked(false);
          analytics.track('Bookmark Removed');
        } else {
          await addBookmark({ gigId: id });
          analytics.track('Bookmark Added');
          setIsGigBookmarked(true);
        }
      } catch (error: any) {
        console.error(error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while bookmarking this gig. Please retry or contact support.',
        });
      } finally {
        setIsBookmarkLoading(false);
      }
    },
    [isGigBookmarked, id, isBookmarkLoading],
  );

  let gigStatusLabel;
  let gigStatusBackgroundColor;

  if (status === GigStatus.Cancelled) {
    gigStatusLabel = GigStatus.Cancelled;
    gigStatusBackgroundColor = Colors.Red;
  }

  if (status === GigStatus.Unassigned && applicationStatus === GigApplicationStatus.Pending) {
    gigStatusLabel = 'Applied';
    gigStatusBackgroundColor = Colors.Green;
  }

  return (
    <Stack
      direction="column"
      sx={{
        ...containerStyle,
      }}
    >
      <Stack
        direction="row"
        sx={{
          marginBottom: '18px',
          marginTop: '18px',
        }}
      >
        <Stack direction="row" onClick={handleClick}>
          {imageUrl && (
            <Box
              sx={{
                width: '56px',
                display: 'flex',
                alignSelf: 'flex-start',
                justifyContent: 'center',
                marginRight: '16px',
              }}
            >
              <Box
                sx={{
                  width: '56px',
                  height: '56px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '6px',
                  backgroundColor: Colors.GrayLight,
                }}
              >
                <img
                  src={imageUrl}
                  width={'56px'}
                  height="56px"
                  alt={'gig preview'}
                  style={{ borderRadius: '6px', objectFit: 'cover' }}
                  onLoad={() => setIsLoadingImage(false)}
                  onError={() => setIsLoadingImage(false)}
                  loading={'lazy'}
                />

                {isLoadingImage && (
                  <Box
                    sx={{
                      width: '56px',
                      height: '56px',
                      position: 'absolute',
                      ...FlexAlign.Center,
                    }}
                  >
                    <CircularProgress size={'sm'} />
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <Stack direction="column">
            <Typography
              sx={{
                ...FontSizes.Header3W700,
                color: 'black',
                marginBottom: '4px',
              }}
            >
              {name}
            </Typography>

            <Stack direction="row">
              <BriefcaseIcon />
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: 'black',
                  marginBottom: '8px',
                  marginLeft: '4px',
                }}
              >
                {`${formattedDateTimeStart} - ${formattedDateTimeEnd}, ${location}, ${city}${
                  zipCode ? `, ${zipCode}` : ''
                }`}
              </Typography>

              {isFulltime && (
                <>
                  <Box
                    sx={{
                      width: '0.5px',
                      height: '16px',
                      backgroundColor: Colors.GrayDark,
                      marginRight: '8px',
                      marginLeft: '8px',
                    }}
                  />
                  <MapPinIcon />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: '14px',
                      lineHeight: '16px',
                      color: 'black',
                      marginBottom: '8px',
                      marginLeft: '4px',
                    }}
                  >
                    Fulltime
                  </Typography>
                </>
              )}
            </Stack>

            <Stack direction="row">
              <Grid container>
                {hourlyRate && (
                  <Grid component={'div'}>
                    <Box sx={{ display: 'flex', marginRight: '8px' }}>
                      <HourlyRateLabel hourlyRate={hourlyRate} />
                    </Box>
                  </Grid>
                )}
                {!isExternal && (
                  <Grid component={'div'}>
                    <Box sx={{ display: 'flex', marginRight: '8px' }}>
                      <QuickHireLabel />
                    </Box>
                  </Grid>
                )}
                {estimatedPrice ? (
                  <Grid component={'div'}>
                    <Box sx={{ display: 'flex', marginRight: '8px' }}>
                      <Typography
                        sx={{
                          ...FontSizes.Header4W700,
                          color: 'black',
                          height: '19px',
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >{`Est. $${estimatedPrice}`}</Typography>
                    </Box>
                  </Grid>
                ) : null}
                <Grid component={'div'}>
                  {bonus && bonus > 0 ? (
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          ...FontSizes.Header4W700,
                          color: 'black',
                          height: '19px',
                          display: 'flex',
                          alignItems: 'center',
                          border: `1px solid ${Colors.Green}`,
                          paddingRight: '2.5px',
                          paddingLeft: '2.5px',
                        }}
                      >{`$${bonus} Bonus`}</Typography>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>

              <HorizontalSpacer />
            </Stack>
          </Stack>
        </Stack>

        <HorizontalSpacer />

        <Stack direction="column">
          <Box
            sx={{
              ...FlexAlign.JustifyEndAlignCenter,
            }}
          >
            {status === GigStatus.Assigned && (
              <Box sx={{ padding: '4px' }}>
                <CheckCircle />
              </Box>
            )}
            {status === GigStatus.Completed && (
              <Box sx={{ padding: '4px' }}>
                <CheckCircle />
              </Box>
            )}
            {status === GigStatus.Unassigned && !isAnonymous && (
              <Box
                onClick={handleBookmarkClick}
                sx={{
                  padding: '4px',
                  width: '16px',
                }}
              >
                {isBookmarkLoading ? (
                  <CircularProgress size={'sm'} />
                ) : isGigBookmarked === true ? (
                  <BookmarkFilled />
                ) : (
                  <BookmarkOutline />
                )}
              </Box>
            )}
          </Box>

          <VerticalSpacer />

          <Typography
            sx={{
              backgroundColor: gigStatusBackgroundColor,
              borderRadius: '4px',
              padding: '2px',
              paddingLeft: '6px',
              paddingRight: '6px',
              color: 'white',
              ...FontSizes.MediumW600,
            }}
          >
            {gigStatusLabel}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GigPreviewItem;
